class ManageProjectUser {

    //Suggests a key based on either a client or project name
    static topform(component) {
        return [
          {
            id: 1,
            name: `${component.$t("ADD_PROJECT_USER.TABS.BASIC")}`,
            errors: component.tab1Errors,
            sections: [
              { 
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericCheckbox",
                        show: component.managementType == 'update',
                        properties: {
                          class: "col-xl-3 px-5 mb-0",
                          id: 'input-suspended',
                          label: `${component.$t("TABLES.PROJECT_USER_TOOLTIPS.PROJECT_BLOCKED")}`,
                          model: component.$v.form.suspended,
                          submissionStates: component.submissionStates
                        }
                      },
                      {
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-3 px-5 mb-0",
                          id: 'input-project-owner',
                          label: `${component.$t("FORMS.INPUTS.PROJECT_OWNER")}`,
                          model: component.$v.form.project_owner,
                          submissionStates: component.submissionStates
                        }
                      },
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.USER_INFORMATION")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-name',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.NAME")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.NAME")}`,
                          model: component.$v.form.name,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.NAME")}`
                        }
                      },
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-display',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.DISPLAY_NAME")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.DISPLAY_NAME")}`,
                          model: component.$v.form.display_name,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.DISPLAY_NAME")}`
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-12 pl-3",
                          id: 'input-email',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.EMAIL")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.EMAIL")}`,
                          model: component.$v.form.email,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.EMAIL")}`
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.USER_PASSWORD")}`,
                show: component.isCurrentUser,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-password',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.PASSWORD")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.PASSWORD")}`,
                          model: component.$v.form.password,
                          submissionStates: component.submissionStates,
                          type: "password",
                          error: `${component.$t("FORMS.ERROR.PASSWORD")}`
                        }
                      },
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-password-confirm',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.PASSWORD_CONFIRM")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.PASSWORD")}`,
                          model: component.$v.form.password_confirmation,
                          submissionStates: component.submissionStates,
                          type: "password",
                          error: `${component.$t("FORMS.ERROR.PASSWORD")}`
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.PROJECT_ROLE")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericRadioOption",
                        properties: {
                          class: "px-5 mb-0",
                          id: 'input-system-project-role',
                          options: component.systemProjectRoles,
                          displayLabel: false,
                          model: component.$v.form.system_project_role_id,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.PROJECT_ROLE")}`,
                          value: 'id',
                          text: 'name'
                        }
                      }
                    ]
                  },
                  {
                    show: component.form.system_project_role_id == 3,
                    components: [
                      {
                        name: "InputGenericTextArea",
                        properties: {
                          class: "col-xl-12 px-5",
                          id: 'input-cm-profile',
                          label: `${component.$t("FORMS.INPUTS.CM_PROFILE")}`,
                          model: component.$v.form.cm_profile,
                          submissionStates: component.submissionStates,
                          rows: "4"
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.PROJECT_USER_SAMPLE")}`,
                show: component.form.system_project_role_id == 1,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericRadioOption",
                        properties: {
                          class: "px-5 mb-0",
                          id: 'input-system-project-user-sample',
                          options: component.systemProjectUserSamples,
                          displayLabel: false,
                          model: component.$v.form.system_project_user_sample_id,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.REQUIRED")}`,
                          value: 'id',
                          text: 'name'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.PROJECT_SEGMENTS")}`,
                show: component.form.system_project_role_id == 1,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericNestedCheckbox",
                        on: {
                          children_changed: (value) => { component.$refs["project-users-topform"].$emit('segments_changed', value) }
                        },
                        properties: {
                          class: "px-5 mb-5",
                          id: 'input-segments',
                          options: component.projectSegmentations,
                          children_key: "project_segments",
                          label_key: "name",
                          alignLabel: "left",
                          showInline: false,
                          submissionStates: component.submissionStates,
                          initialSelection: component.initialSegmentSelection
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            name: `${component.$t("ADD_PROJECT_USER.TABS.EXTENDED")}`,
            errors: component.tab2Errors,
            sections: [
              {
                show: component.calculatedStatus == "invited",
                rows: [
                  {
                    components: [
                      {
                        name: 'InputGeneric',
                        properties: {
                          class: "col-xl-12 pr-3",
                          id: 'input-registration-link',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t('FORMS.INPUTS.REGISTRATION_LINK')}`,
                          placeholder: `${component.$t('FORMS.INPUTS.REGISTRATION_LINK')}`,
                          model: component.$v.form.setupURL,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.REGISTRATION_LINK")}`,
                          disabled: true
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.LOCATION_INFORMATION")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-address1',
                          alignLabel: "left",
                          label: `${component.$t("FORMS.INPUTS.ADDRESS1")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.ADDRESS1")}`,
                          showInline: false,
                          model: component.$v.form.address1,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.ADDRESS1")}`,
                        }
                      },
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-address2',
                          alignLabel: "left",
                          label: `${component.$t("FORMS.INPUTS.ADDRESS2")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.ADDRESS2")}`,
                          showInline: false,
                          model: component.$v.form.address2,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.ADDRESS2")}`,
                        }
                      },
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-city',
                          alignLabel: "left",
                          label: `${component.$t("FORMS.INPUTS.CITY")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.CITY")}`,
                          showInline: false,
                          model: component.$v.form.city,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.CITY")}`,
                        }
                      },
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-state',
                          alignLabel: "left",
                          label: `${component.$t("FORMS.INPUTS.STATE")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.STATE")}`,
                          showInline: false,
                          model: component.$v.form.state,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.STATE")}`,
                        }
                      },
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-postcode',
                          alignLabel: "left",
                          label: `${component.$t("FORMS.INPUTS.POSTCODE")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.POSTCODE")}`,
                          showInline: false,
                          model: component.$v.form.postcode,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.POSTCODE")}`,
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.EXTENDED_USER_INFORMATION")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericDatePicker",
                        on: {
                          date_updated: (value) => { component.$refs["project-users-topform"].$emit('date_of_birth_changed', value) }
                        },
                        properties: {
                          class: "col-xl-6 pr-3 mb-0",
                          id: 'input-date-of-birth',
                          label: `${component.$t("FORMS.INPUTS.DATE_OF_BIRTH")}`,
                          model: component.$v.form.date_of_birth,
                          submissionStates: component.submissionStates,
                          type: "date",
                          storedAsDate: true,
                          error: `${component.$t("FORMS.ERROR.DATE_OF_BIRTH")}`,
                        }
                      },
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-contact-number',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.CONTACT")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.CONTACT")}`,
                          model: component.$v.form.contact_number,
                          submissionStates: component.submissionStates,
                          error: `${component.$t('FORMS.ERROR.CONTACT')}`,
                        }
                      },
                    ]
                  },
                  {
                    components: [
                      {
                        name: 'InputGeneric',
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-external-reference',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t('FORMS.INPUTS.EXTERNAL')}`,
                          placeholder: `${component.$t('FORMS.INPUTS.EXTERNAL')}`,
                          model: component.$v.form.external_reference,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.EXTERNAL")}`,
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.USER_LOCALE")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-country-code',
                          label: `${component.$t("FORMS.INPUTS.COUNTRY")}`,
                          placeholder: `${component.$t("FORMS.PLACEHOLDER.COUNTRY")}`,
                          model: component.$v.form.system_country_code,
                          options: component.systemCountries,
                          optionLabel: "name",
                          value: "code",
                          errorMessage: `${component.$t("FORMS.ERROR.COUNTRY")}`,
                        }
                      },
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-language-code',
                          label: `${component.$t("FORMS.INPUTS.LANGUAGE")}`,
                          placeholder: `${component.$t("FORMS.PLACEHOLDER.LANGUAGE")}`,
                          model: component.$v.form.system_language_code,
                          options: component.systemLanguages,
                          optionLabel: "name",
                          value: "code",
                          errorMessage: `${component.$t("FORMS.ERROR.LANGUAGE")}`,
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-6 pr-3",
                          id: 'input-timezone-code',
                          label: `${component.$t("FORMS.INPUTS.TIMEZONE")}`,
                          placeholder: `${component.$t("FORMS.PLACEHOLDER.TIMEZONE")}`,
                          model: component.$v.form.system_timezone_code,
                          options: component.systemTimezones,
                          optionLabel: "name",
                          value: "code",
                          errorMessage: `${component.$t("FORMS.ERROR.TIMEZONE")}`,
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.USER_AVATAR")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "IntegratedDropzone",
                        on: {
                          asset_reservation_created: (value) => { component.$refs["project-users-topform"].$emit('asset_reservation_created', value) },
                          asset_reservation_deleted: (value) => { component.$refs["project-users-topform"].$emit('asset_reservation_deleted', value) },
                          remove_project_asset: (value) => { component.$refs["project-users-topform"].$emit('remove_project_asset', value) },
                        },
                        properties: {
                          class: "col-xl-8 px-3",
                          assetReservation: component.assetReservationData,
                          idx: 1,
                          projectAssets: component.gallery,
                          wholeWidth: true,
                          removable: true
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id: 3,
            name: `${component.$t("ADD_PROJECT_USER.TABS.NOTES")}`,
            errors: component.tab3Errors,
            sections: [
              {
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericTextArea",
                        properties: {
                          class: "col-xl-12",
                          id: "input-user-notes",
                          label: `${component.$t("FORMS.INPUTS.NOTES")}`,
                          model: component.$v.form.notes,
                          submissionStates: component.submissionStates,
                          rows: "8"
                        }
                      }
                    ]
                  }
                ]
              },
              {
                rows: [
                  {
                    width: "fluid",
                    components: [
                      {
                        name: "EventsTable",
                        properties: {
                          class: "col-xl-12",
                          id: "input-user-events",
                          submissionStates: component.submissionStates,
                          events: component.eventList,
                          title: `${component.$t('TABLES.PROJECT_USER_TOOLTIPS.EVENTS')}`
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
    }
  
  }
  
export default ManageProjectUser;