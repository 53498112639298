<template>
  <div class="row col-xl-12 mx-0 px-0">
    <!--start::TOPFORM-->
    <Topform
      v-if="validationError.active == false"
      ref="project-users-topform"
      :managementType="managementType"
      :status="calculatedStatus"
      pageType="project_user"
      :name="projectUser.name"
      :errors="projectUsersErrors"
      :submissionStates="submissionStates"
      parentPage="/project/users"
      :tabs="computedTabs"
      :validation="computedValidation"
      :loadingComplete="loadingComplete"
      @clear_errors="clearErrors()"
      @segments_changed="form.segments = $event"
      @date_of_birth_changed="form.date_of_birth = $event"
      @asset_reservation_created="form.profile_picture = $event"
      @asset_reservation_deleted="form.profile_picture = null"
      @remove_project_asset="removeAvatar"
      @clear_existing_data="clearData"
      @submit="submit"
    >
    </Topform>
    <!--end::TOPFORM-->
    <div v-if="validationError.active == false" class="col-xl-4 pr-0 pl-2">
      <div v-if="loadingComplete" class="card card-custom gutter-b">
        <div class="card-body">
          <h6 class="mb-10">{{ $t('ADD_PROJECT_USER.HELPERS.TITLE') }}</h6>
          <template v-for="(role, i) in systemProjectRoles">
            <div :key="i">
              <p class="font-size-sm font-weight-bold mb-2">{{ $t('SYSTEM.PROJECT_ROLES.' + role.id) }}</p>
              <p class="font-size-sm mb-7">{{ $t('ADD_PROJECT_USER.HELPERS.' + role.id) }}</p>
            </div>
          </template>
        </div>
      </div>
    </div>

    <ErrorBox class="col-12" v-else :errors="{ message: 'You do not have the correct permissions to view this resource' }"></ErrorBox>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required, email, requiredIf } from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import PageLoader from "@/core/classes/PageLoader.js";
import Toast from "@/core/classes/Toast.js";
import ErrorBox from "@/view/components/ErrorBox";

import {
  CREATE_PROJECT_USER,
  UPDATE_PROJECT_USER,
  CLEAR_PROJECT_USERS_ERRORS,
  CLEAR_PROJECT_USERS_INFO
} from "@/core/services/store/project/project_users.module";

import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManageProjectUser.js";

export default {
  mixins: [validationMixin],

  name: "ManageProjectUser",
  
  data() {
    return {
      tab: 1,
      pageLoader: new PageLoader(2),
      toast: new Toast(),
      submissionStates: {
        submitting: false
      },
      error: {
        active: false,
        message: ""
      },
      validationError: {
        active: false,
        message: ""
      },
      form: {
        suspended: 1,
        project_owner: 0,
        name: "",
        display_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        system_project_role_id: 1,
        system_project_user_sample_id: 1,
        cm_profile: "",
        segments: [],
        address1: "",
        address2: "",
        city: "",
        state: "",
        postcode: "",
        date_of_birth: "",
        contact_number: "",
        notes: "",
        external_reference: "",
        setupURL: "",
        system_country_code: null,
        system_language_code: "eng",
        system_timezone_code: "UTC",
        profile_picture: null,
        profile_picture_remove: null
      },
      initialSegmentSelection: [],
      initialAvatar: [],
      allow_name_editing: true
    }
  },

  validations: {
    form: {
      suspended: {
        required
      },
      project_owner: {
        required
      },
      name: {
        maxLength: maxLength(255)
      },
      display_name: {
        required,
        maxLength: maxLength(255),
        isValid: (value, vm) => {
          let spaceLessValue = value.replace(/\s/g, '').toLowerCase();
          let spaceLessName = vm.name.replace(/\s/g, '').toLowerCase();
          let check1 = !spaceLessValue.includes(spaceLessName)
          let check2 = !spaceLessName.includes(spaceLessValue)
          return check1 && check2;
        }
      },
      email: {
        required,
        maxLength: maxLength(255),
        email
      },
      system_project_role_id: {
        required
      },
      system_project_user_sample_id: {
        required: requiredIf(function() {
          return this.form.system_project_role_id == 1
        })
      },
      cm_profile: {},
      address1: {
        maxLength: maxLength(255)
      },
      address2: {
        maxLength: maxLength(255)
      },
      city: {
        maxLength: maxLength(255)
      },
      state: {
        maxLength: maxLength(255)
      },
      postcode: {
        maxLength: maxLength(255)
      },
      date_of_birth: {},
      contact_number: {},
      notes: {},
      external_reference: {
        maxLength: maxLength(255)
      },
      setupURL: {},
      system_country_code: {},
      system_language_code: {},
      system_timezone_code: {},
      password: {
        minLength: minLength(8),
        maxLength: maxLength(24)
      },
      password_confirmation: {
        minLength: minLength(8),
        maxLength: maxLength(24)
      }
    },
    initialAvatar: {}
  },

  components: {
    ErrorBox
  },

  mounted() {
    if (this.currentUser.relation_to_project != "super_admin" && this.currentUser.relation_to_project != "admin") {
      this.validationError.active = true;
      this.validationError.message = "You do not have the correct permissions to view this resource";
    } 

    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      { title: this.clientInfo.name, route: protocol + this.clientInfo.key + ".admin." + process.env.VUE_APP_API_ROOT + "/#/client/projects" },
      {
        title: this.projectInfo.name,
        route: "/project/view"
      },
      { title: `${this.$t("TABLES.PROJECT_USER_NAVS.ADD")}` }
    ]);

    this.fetchProjectSegmentations();

    if (this.$route.params.id != undefined) {
      this.pageLoader.addRequiredComponent(1);
      this.fetchExistingUser();
      this.fetchUserEvents();
    } else {
      this.autofillProjectSettings();
    }
  },

  methods: {
    autofillDisplay: function() {
      if (this.managementType == "post" && this.allow_name_editing) {
        this.form.display_name = this.form.name;
      }
    },
    fetchProjectSegmentations: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: 'with',
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    fetchExistingUser: function() {

      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/users/${this.$route.params.id}`,
        data: {
          projectId: this.projectInfo.id
        },
        attributes: [
          'projectRelation'
        ],
      }

      this.$store.dispatch(GENERIC_RESOURCE, payload)
        .then((res) => {
          let projectUser = res;
          this.setProjectUsersInfo(projectUser);

          for (const [key, value] of Object.entries(projectUser)) {
            if (this.form[key] != undefined) {
              this.form[key] = value;
            }
          }
          let invite = projectUser.assigned_project_invites;
          let profile = projectUser.project_profiles;
          let data = (profile[0] != undefined) ? profile[0] : invite[0];
          let segments = (profile[0] != undefined) ? projectUser.active_segments : projectUser.invited_segments;
          let initialSegments = [];

          for (const [key, value] of Object.entries(data)) {
            if (this.form[key] != undefined) {
              this.form[key] = value;
            }
          };
          segments.forEach(segment => {
            let data = { parent: segment.project_segmentation_id, id: segment.id };
            initialSegments.push(data);
          });
          this.initialSegmentSelection = initialSegments;
          if (projectUser.avatar != null) {
            this.initialAvatar = projectUser.project_asset;
          }
          this.form.name = projectUser.calculatedName;
          this.form.email = projectUser.calculatedEmail;
          this.form.suspended = (this.calculatedStatus == 'project blocked' || projectUser.suspended == 1) ? 1 : 0;
          this.form.system_country_code = data.system_country_code;

          this.pageLoader.setCompleteComponent();
        })
        .catch((error) => {
          this.setProjectUsersErrors(error.message);
        });
    },
    fetchUserEvents: function() {
      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/events`,
        params: {
          paginate: false
        },
        filters: {
          target_type: "User",
          target_id: this.$route.params.id 
        }
      };

      this.genericResource(payload).then((res) => {
        this.setEventList(res._embedded.events);
        this.pageLoader.setCompleteComponent();
      })
    },
    autofillProjectSettings: function() {
      this.form.system_language_code = this.projectInfo.system_language_code;
      this.form.system_timezone_code = this.projectInfo.system_timezone_code;
      this.pageLoader.setCompleteComponent();
    },
    submit: function() {
      this.clearErrors();

      if (this.managementType == "update") {
        this.submitUpdate();
      } else {
        this.submitPost();
      }
    },
    submitPost: function() {
      this.submissionStates.submitting = true;

      let payload = {
        projectId: this.projectInfo.id,
        data: this.form
      }

      if (payload.data.system_project_role_id != 1) {
        payload.data.segments = [];
      };

      this.$store
        .dispatch(CREATE_PROJECT_USER, payload)
        .then(() => {
          (this.form.suspended == 1) ? this.toastStoreNoInvite() : this.toastStore();
          this.$router.push({ name: 'project_users' });
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    submitUpdate: function() {
      this.submissionStates.submitting = true;

      let payload = {
        projectId: this.projectInfo.id,
        userId: this.$route.params.id,
        data: this.form
      }

      if (payload.data.system_project_role_id != 1) {
        payload.data.segments = [];
      };

      this.$store
        .dispatch(UPDATE_PROJECT_USER, payload)
        .then(() => {
          if (this.form.name != "") {
            this.toast.notify(this, this.form.name + ' ' + `${this.$t("TOASTS.EDIT_PROJECT_USER.MESSAGE")}`, `${this.$t("TOASTS.EDIT_PROJECT_USER.TITLE")}`, 'success');
          } else {
            this.toast.notify(this, this.form.email + ' ' + `${this.$t("TOASTS.EDIT_PROJECT_USER.MESSAGE")}`, `${this.$t("TOASTS.EDIT_PROJECT_USER.TITLE")}`, 'success');
          }
          this.$router.push({ name: 'project_users' });
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    clearData: function() {
      this.$store.dispatch(CLEAR_PROJECT_USERS_INFO)
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_USERS_ERRORS);
    },
    removeAvatar: function(value) {
      this.form.profile_picture_remove = value;
      this.form.profile_picture = null;
      this.initialAvatar = null;
    },
    toastStore: function() {
      if (this.form.name != "") {
        this.toast.notify(this, this.form.name + ' ' + `${this.$t("TOASTS.ADD_PROJECT_USER.MESSAGE")}`, `${this.$t("TOASTS.ADD_PROJECT_USER.TITLE")}`, 'success');
      } else {
        this.toast.notify(this, this.form.email + ' ' + `${this.$t("TOASTS.ADD_PROJECT_USER.MESSAGE")}`, `${this.$t("TOASTS.ADD_PROJECT_USER.TITLE")}`, 'success');
      }
    },
    toastStoreNoInvite: function() {
      if (this.form.name != "") {
        this.toast.notify(this, this.form.name + ' ' + `${this.$t("TOASTS.ADD_PROJECT_USER_NO_INVITE.MESSAGE")}`, `${this.$t("TOASTS.ADD_PROJECT_USER_NO_INVITE.TITLE")}`, 'warning');
      } else {
        this.toast.notify(this, this.form.email + ' ' + `${this.$t("TOASTS.ADD_PROJECT_USER_NO_INVITE.MESSAGE")}`, `${this.$t("TOASTS.ADD_PROJECT_USER_NO_INVITE.TITLE")}`, 'warning');
      }
    },
    ...mapMutations({
      setProjectUsersInfo: "setProjectUsersInfo",
      setProjectUsersErrors: "setProjectUsersErrors",
      setEventList: "setEventList"
    }),
    ...mapActions({
      genericResource: "genericResource"
    })
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "clientInfo",
      "projectInfo",
      "systemProjectRoles",
      "systemCountries",
      "systemLanguages",
      "systemTimezones",
      "systemProjectUserSamples",
      "projectSegmentations",
      "projectUsersErrors",
      "projectUser",
      "eventList"
    ]),
    calculatedStatus: function() {
      if (this.projectUser.projectRelation != undefined) {
        switch (this.projectUser.projectRelation.setup) {
          case 1:
            return "system blocked";
          case 2:
            return "pending invitation";
          case 3:
          default:
            return "invited";
          case 4:
            return "subscribed";
          case 5:
            return "project blocked"
        }           
      } else {
        return null;
      }
    },
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },
    managementType: function() {
      if (this.$route.params.id != undefined) {
        return "update";
      } else {
        return "post"
      }
    },
    assetReservationData: function() {
      let data = {
        scope: 'user',
        data: {
          directory: 'avatars',
          projectId: this.projectInfo.id
        }
      }

      if (this.managementType == "post") {
        data.data.new = 1;
      } else {
        data.data.userId = this.$route.params.id
      }

      return data;
    },
    gallery: function() {
      let gallery = [];

      if (this.managementType == "update") {
        if (this.projectUser.avatar != null) {
          if (this.projectUser.project_asset.id != this.form.profile_picture_remove) {
            gallery.push(this.projectUser.project_asset);
          }
        }
      }
      
      return gallery;
    },
    tab1Errors: function() {
      if (
        this.$v.form.name.$anyError ||
        this.$v.form.display_name.$anyError ||
        this.$v.form.email.$anyError ||
        this.$v.form.system_project_role_id.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    },
    tab2Errors: function() {
      if (
        this.$v.form.address1.$anyError ||
        this.$v.form.address2.$anyError ||
        this.$v.form.city.$anyError ||
        this.$v.form.state.$anyError ||
        this.$v.form.postcode.$anyError ||
        this.$v.form.date_of_birth.$anyError ||
        this.$v.form.contact_number.$anyError ||
        this.$v.form.external_reference.$anyError ||
        this.$v.form.setupURL.$anyError ||
        this.$v.form.system_country_code.$anyError ||
        this.$v.form.system_language_code.$anyError ||
        this.$v.form.system_timezone_code.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    },
    tab3Errors: function() {
      if (this.$v.form.notes.$anyError) {
        return true;
      } else {
        return false;
      }
    },
    isCurrentUser: function() {
      if (this.managementType == "post") {
        return false;
      } else {
        if (this.currentUser.id == this.$route.params.id) {
          return true;
        } else {
          return false;
        }
      }
    },
    computedValidation: function() {
      return this.$v.form;
    },
    computedTabs: function() {
      return TopformConfig.topform(this);
    },
    calculatedName: function() {
      return this.form.name;
    },
    calculatedDisplay: function() {
      return this.form.display_name;
    }
  }
};
</script>
